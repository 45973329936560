div.gm-style-iw button.gm-ui-hover-effect {
  top: -2px !important;
  right: 2px !important;
}

div.gm-style-iw button.gm-ui-hover-effect img {
  width: 18px !important;
  height: 18px !important;
}

.avela-explore-google-map *:focus-visible,
.avela-explore-google-map *:focus {
  outline-color: var(--chakra-colors-secondary);
  outline-offset: 1px;
  outline-style: solid;
  outline-width: 3px;
}
.avela-explore-google-map *:focus:not(:focus-visible) {
  outline: none;
}

.pac-container {
  z-index: 9999 !important;
}
